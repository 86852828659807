<template>
  <div class="p-pegas">
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
          <div class="card-table-body ifon-add-title-block">
              <el-row :gutter="20">
                  <el-col :span="12">
                      <div class="caz-blocks-sarcho-title">
                          <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">
                              {{ $t("message.vacation_managements") }}
                          </div>
                          <div class="block-sarche">
                              <div class="header__search">
                                  <crm-input
                                      :size="'small'"
                                      :className="'w100'"
                                      :class="mode ? 'input__day' : 'input__night'"
                                      v-model="filterForm.search"
                                      :icon="'el-icon-search'"
                                  ></crm-input>
                              </div>
                          </div>
                      </div>
                  </el-col>

                  <el-col :span="12" class="flex-style text-right">
                      <crm-create-and-column-settings
                          :permission="$options.name"
                          @c-create="drawerCreate = false"
                          :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                          :columns="columns"
                          @c-change="updateColumn"
                          :can_create="false"
                          :v_can_create="'VacationManagementController@store'"
                      >
                      </crm-create-and-column-settings>
                  </el-col>
              </el-row>
          </div>
          <!-- end ifon-add-title-block -->

          <div class="card-table-header table-crm-smart">
            <table
                class="table-my-code table-bordered"
                :class="mode ? 'table__myday' : 'table__mynight'"
                v-loading="loadingData"
            >
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.staff.show">
                            {{ columns.staff.title }}
                        </th>
                        <th v-if="columns.branch.show">
                          {{ columns.branch.title }}
                        </th>
                        <th v-if="columns.department.show">
                          {{ columns.department.title }}
                        </th>
                        <th v-if="columns.position.show">
                          {{ columns.position.title }}
                        </th>
                        <th v-if="columns.peryot_year.show">
                          {{ columns.peryot_year.title }}
                        </th>
                        <th v-if="columns.peryot_date.show">
                          {{ columns.peryot_date.title }}
                        </th>
                        <th v-if="columns.comment.show">
                          {{ columns.comment.title }}
                        </th>
                        <th v-if="columns.status.show">
                          {{ columns.status.title }}
                        </th>
                        <th v-if="columns.created_at.show">
                          {{ columns.created_at.title }}
                        </th>
                        <th v-if="columns.updated_at.show">
                            {{ columns.updated_at.title }}
                        </th>
                        <!-- <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th> -->
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.staff.show">
                          <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.staff.show">
                          <select-staff
                          :class="mode ? 'filter__day' : 'filter__night'"
                          :size="'mini'"
                          v-model="filterForm.staff_id"
                        ></select-staff>
                        </th>
                        <th v-if="columns.branch.show">
                          <select-branch
                              :placeholder="columns.branch.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.branch_id"
                            >
                          </select-branch>
                        </th>
                        <th v-if="columns.department.show">
                          <select-department
                              :placeholder="columns.department.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.department_id"
                            >
                          </select-department>
                        </th>
                        <th v-if="columns.position.show">
                          <select-position
                              :placeholder="columns.position.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.position_id"
                            >
                          </select-position>
                        </th>
                        <th v-if="columns.peryot_year.show">
                          <!-- <el-date-picker
                              :class="mode ? 'filter__day' : 'filter__night'"
                              class="w-100"
                              size="mini"
                              v-model="filterForm.month"
                              type="month"
                              :format="'MMMM yyyy'"
                              :value-format="'MM.yyyy'"
                              :placeholder="$t('message.monthly')"
                            >
                           </el-date-picker> -->
                        </th>
                        <th v-if="columns.peryot_date.show">
                        
                        </th>
                        <th v-if="columns.comment.show">
                        
                        </th>
                        <th v-if="columns.status.show"
                        ></th>
                        <th v-if="columns.created_at.show">
                              <crm-date-picker
                                :placeholder="columns.created_at.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.created_at"
                            ></crm-date-picker>
                        </th>
                        <th v-if="columns.updated_at.show">
                            <crm-date-picker
                                :placeholder="columns.updated_at.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.updated_at"
                            ></crm-date-picker>
                        </th>
                    </tr>
                </thead>
                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="vacationManagement in list"
                        :key="vacationManagement.id"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">{{ vacationManagement.id }}</td>

                        <td v-if="columns.staff.show">
                            {{ vacationManagement.staff.name }} {{ vacationManagement.staff.last_name }}
                        </td>
                        <td v-if="columns.branch.show">
                          {{ vacationManagement.staff&&vacationManagement.staff.department&&vacationManagement.staff.department.branch?vacationManagement.staff.department.branch.name:'' }}
                        </td>
                        <td v-if="columns.department.show">
                          {{ vacationManagement.staff&&vacationManagement.staff.department?vacationManagement.staff.department.name:'' }}
                        </td>
                        <td v-if="columns.position.show">
                          {{ vacationManagement.position.name }}
                        </td>
                        <td v-if="columns.peryot_year.show">
                          {{ formatDate(vacationManagement.vacation_month_from) }} - {{ formatDate(vacationManagement.vacation_month_to) }}
                        </td>
                        <td v-if="columns.peryot_date.show">
                          {{ formatPeryotDate(vacationManagement.from_date) }} - {{ formatPeryotDate(vacationManagement.to_date) }}
                        </td>
                        <td v-if="columns.comment.show">
                          {{ vacationManagement.comment }}
                        </td>
                        <td v-if="columns.status.show">
                          <div>
                            <div v-if="vacationManagement.status == 'waiting'">
                              <div v-if="vacationManagement.from_date <= now">
                                  <el-button   type="success" size="mini" class="remove-button" @click="setNewStatus('accept',vacationManagement.id)">{{$t("message.accept")}}</el-button>
                                  <el-button   type="danger" size="mini" class="remove-button" @click="denyStatus('deny',vacationManagement.id)">{{$t("message.cancel")}}</el-button>
                              </div>
                              <div v-else>
                                <el-tag type="warning"   effect="dark" style="margin-right:5px; margin-bottom: 5px;" >{{$t('message.rejected')}}</el-tag>
                              </div>
                            </div>
                            <div v-else-if="vacationManagement.status == 'accept'"  >
                              <el-tag type="success"   effect="dark" style="margin-right:5px; margin-bottom: 5px;" >{{$t('message.allowed')}}</el-tag>
                            </div>
                            <div v-else>
                              <el-tag type="danger"   effect="dark" style="margin-right:5px; margin-bottom: 5px;" >{{$t('message.rejected')}}</el-tag>
                            </div>
                          </div>
                        </td>
                        <td v-if="columns.created_at.show">
                            {{ vacationManagement.created_at }}
                        </td>
                        <td v-if="columns.updated_at.show">
                            {{ vacationManagement.updated_at }}
                        </td>
                        <!-- <td v-if="columns.settings.show" class="settings-td">
                            <crm-settings
                                :name="$options.name"
                                :model="vacationManagement"
                                :permissionShow="'VacationManagementController@update'"
                                :permissionDestroy="'VacationManagementController@destroy'"
                                :actions="actions"
                                @edit="edit"
                                @delete="destroy"
                            ></crm-settings>
                        </td> -->
                    </tr>
                </transition-group>
            </table>
            <div class="my___pagination">
                <crm-pagination
                    @c-change="updatePagination"
                    :class="mode ? 'pagination__day' : 'pagination__night'"
                    :pagination="pagination"
                ></crm-pagination>
            </div>
        </div>

          <div class="app-modal app-modal__full">
              <!-- <el-drawer
                  :with-header="false"
                  :visible.sync="drawerCreate"
                  size="70%"
                  ref="drawerCreate"
                  @opened="drawerOpened('drawerCreateChild')"
                  @closed="drawerClosed('drawerCreateChild')"
              >
                  <crm-create
                      ref="drawerCreateChild"
                      drawer="drawerCreate"
                  ></crm-create>
              </el-drawer>

              <el-drawer
                  :with-header="false"
                  :visible.sync="drawerUpdate"
                  size="70%"
                  ref="drawerUpdate"
                  @opened="drawerOpened('drawerUpdateChild')"
                  @closed="drawerClosed('drawerUpdateChild')"
              >
                  <crm-update
                      :selectedItem="selectedItem"
                      ref="drawerUpdateChild"
                      drawer="drawerUpdate"
                  ></crm-update>
              </el-drawer> -->

              <el-dialog
                  :visible.sync="dialogSetStatus"
                  width="60%"
                  :append-to-body="true"
                  center
                  ref="dialogSetStatus"
                  :title="$t('message.comment')"
                  :class="mode ? 'dialog__myday' : 'dialog__mynight'">
                    <div>
                      <el-form ref="form" status-icon :model="form"  v-if="form">
                        <el-row :gutter="20">
                        <el-col :span="12">
                          <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="payment_month">
                            <span class="input--label d-block mb-0 line-h-24 title_data" :class="mode ? 'text__day2' : 'text__night2'">
                              <span class="start_top">*</span>
                              {{ $t("Период Год") }}
                            </span>
                            <el-date-picker
                              v-model="form.period_year"
                              type="monthrange"
                              align="right"
                              unlink-panels
                              range-separator="-"
                              :start-placeholder="$t('message.monthly')+' '+ $t('message.from')"
                              :end-placeholder="$t('message.monthly')+' '+ $t('message.before')"
                              :format="'MMMM yyyy'"
                              :value-format="'yyyy-MM-dd'"
                              style="width:100%">
                            </el-date-picker>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="payment_month">
                            <span class="input--label d-block mb-0 line-h-24 title_data" :class="mode ? 'text__day2' : 'text__night2'">
                              <span class="start_top">*</span>
                              {{ $t("Период День") }}
                            </span>
                            <el-date-picker
                              v-model="form.period_date"
                              type="daterange"
                              align="right"
                              unlink-panels
                              range-separator="-"
                              :start-placeholder="$t('message.from_data')"
                              :end-placeholder="$t('message.to_data')"
                              :format="'dd.MM.yyyy'"
                              :value-format="'yyyy-MM-dd'"
                              style="width:100%">
                            </el-date-picker>
                            <!-- <el-date-picker
                              class="w-100"
                              size="medium"
                              v-model="form.month"
                              type="month"
                              :format="'MMMM yyyy'"
                              :value-format="'yyyy-MM'"
                              :placeholder="$t('message.monthly')"
                            >
                            </el-date-picker> -->
                          </el-form-item>
                        </el-col>
                        <el-col :span="24">
                          <el-form-item>
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                              >{{ $t("message.comment") }}</span>
                              <el-input
                                  type="textarea"
                                  :rows="4"
                                  :placeholder="$t('message.comment')"
                                  :inputValue="form.comment"
                                  v-model="form.comment"
                                  class="text-area-el"
                              ></el-input>
                          </el-form-item>
                        </el-col>
                    </el-row>
                      </el-form>
                      <span slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="setAcceptStatus()" v-loading="loadingButton">
                          Сохранить
                        </el-button>
                        <el-button type="warning" @click="close()"> Закрыть </el-button>
                      </span>
                    </div>
              </el-dialog>
          </div>
          
      </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import moment from 'moment';
import selectStaff from "@/components/filters/inventory/select-staff";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { i18n } from "@/utils/i18n";
import form from "@/utils/mixins/form";
import dialog from "@/utils/mixins/dialog";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "RegionController",
  mixins: [list, form, dialog],
  components: {
      CrmCreate,
      CrmUpdate,
      selectStaff
  },

  data() {
      return {
        dialogSetStatus:false,
        loadingData:false, 
        now :moment().format('YYYY-MM-DD'),
        selected:{
          value:'',
          id:''
        }
      };
  },
  computed: {
      ...mapGetters({
          list: "vacationManagement/list",
          columns: "vacationManagement/columns",
          pagination: "vacationManagement/pagination",
          statues: "vacationManagement/statues",
          filter: "vacationManagement/filter",
          sort: "vacationManagement/sort",
          mode: "MODE"
      }),
      actions: function () {
          return ["edit", "delete"];
      },
  },
  methods: {
      ...mapActions({
          updateList: "vacationManagement/index",
          setPagination: "vacationManagement/setPagination",
          updateSort: "vacationManagement/updateSort",
          updateFilter: "vacationManagement/updateFilter",
          updateColumn: "vacationManagement/updateColumn",
          updateStatus: "vacationManagement/updateStatus",
          updatePagination: "vacationManagement/updatePagination",
          show: "vacationManagement/show",
          empty: "vacationManagement/empty",
          delete: "vacationManagement/destroy",
          refreshData: "vacationManagement/refreshData",
      }),
      formatDate(value){
        if (value) {
          return moment((value)).lang("ru").format('MMMM YYYY')
        }
      },
      formatPeryotDate(value){
        if (value) {
          return moment((value)).lang("ru").format('DD.MM.YYYY')
        }
      },
      setNewStatus(value,id){
          var query = {
            status:value,
            id:id
          }
          this.updateStatus(query)
              .then((res) => {
                  this.refreshData();
                  this.$alert(res);
              })
              .catch((err) => {
                  this.$alert(err);
              });
      },
      setAcceptStatus(){
        this.dialogSetStatus =false;
           var query = {
            status:this.selected.value,
            id:this.selected.id,
            data:this.form
          }
          this.updateStatus(query)
              .then((res) => {
                this.closeDialog();
                this.refreshData();
                this.$alert(res);
              })
              .catch((err) => {
                  this.$alert(err);
              });
      },

      denyStatus(value,id){
        this.dialogSetStatus =true;
        this.selected.value=value;
        this.selected.id=id;
        this.show(id).then((res) => {
                  this.form = JSON.parse(JSON.stringify(res.data.result.data.vacation_management));
                  this.loadingData = false;
                })
                .catch((err) => {
                  this.loadingData = false;
                });
      },

      closeDialog(){
        this.form=null;
        this.selected.value='';
        this.selected.id='';
      }
    

  },
};
</script>

